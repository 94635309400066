<script setup lang="ts">
const userStore = useUserStore()
const router = useRouter()
const currentRoute = useRoute()

const loadingApp = ref(true)

useHead({
  title: 'Notdienstportal',
  meta: [
    { name: 'description', content: 'sberg Notdienstportal' },
    {
      name: 'theme-color',
      content: () => isDark.value ? '#00aba9' : '#ffffff',
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: () => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg',
    },
  ],
})

onMounted(() => {
  loadingApp.value = true

  api.getNutzerInfo().then((res) => {
    userStore.setUser(res.data.nutzer)
    userStore.setBackendVersion(res.data.version)
    // TODO remove, info is in nutzer enthalten
    userStore.setNotdienstaenderungenEingabeZusaetzlichesMerkmal(res.data.nutzer.nutzerEinstellung.notdienstaenderungenEingabeZusaetzlichesMerkmal)
    userStore.setReducedUserList([])
    loadingApp.value = false

    if (!userStore.canNutzer && (currentRoute.name === 'nutzer' || currentRoute.name === 'detail-nutzer-id'))
      router.push('/')
    if (!userStore.canApotheken && (currentRoute.name === 'apotheke' || currentRoute.name === 'detail-apotheke-id'))
      router.push('/')
    if (!userStore.canRst && (currentRoute.name === 'rst' || currentRoute.name === 'detail-rst-id'))
      router.push('/')
    if (!userStore.canPlaene && (currentRoute.name === 'plan' || currentRoute.name === 'detail-plan-id'))
      router.push('/')
    if (!userStore.canAntraege && (currentRoute.name === 'aenderungsantrag'))
      router.push('/')
    if (!userStore.canEntfernungen && (currentRoute.name === 'entfernung'))
      router.push('/')
    if (!userStore.canNNF && (currentRoute.name === 'nnf' || currentRoute.name === 'detail-nnf-id'))
      router.push('/')
    if (!userStore.canKarte && (currentRoute.name === 'karte'))
      router.push('/')
    if (!userStore.canAdmin && (currentRoute.name === 'admin'))
      router.push('/')
    if (!userStore.canSchnittstelleNutzer && (currentRoute.name === 'schnittstellenutzer' || currentRoute.name === 'detail-schnittstellenutzer-id'))
      router.push('/')
    if (!userStore.canNewsletterNutzer && (currentRoute.name === 'newsletternutzer' || currentRoute.name === 'detail-newsletternutzer-id'))
      router.push('/')
  }).catch((err: any) => {
    userStore.setUser(undefined)
    userStore.setToken(undefined)
    localStorage.removeItem('token')
    loadingApp.value = false

    // Token abgelaufen oder Nutzer nicht angemeldet oder Server nicht erreichbar
    // Zeige Fehler-Modal wenn ein Netzwerkfehler auftritt
    if (err.code === 'ERR_NETWORK')
      useModal.handleError(undefined, err)
  })

  setTimeout(() => initFlowbite(), 500)
})
</script>

<template>
  <div class="h-full">
    <div v-if="loadingApp" class="backdrop login-bg z-19" />

    <Transition v-if="loadingApp" name="fade" mode="out-in">
      <div v-if="loadingApp" class="float absolute left-a z-20 h-full w-full flex flex-col items-center justify-center text-center">
        <div class="text-xl">
          Notdienstportal wird geladen
        </div>
        <div class="mb-4 text-xl">
          Bitte warten...
        </div>
        <div class="circle-loader z-20 h-30 w-30" />
      </div>
    </Transition>

    <RouterView v-else-if="userStore && userStore.token && userStore.savedUser" />

    <Transition v-else name="fade" mode="out-in">
      <TheLogin />
    </Transition>

    <div id="snackbar">
      <p class="b-1 bg-white p-4 text-sm text-black leading-5 sm:max-w-3xl dark:bg-gray-800 dark:text-gray-500 dark:text-white">
        In Zwischenablage kopiert
      </p>
    </div>
  </div>
</template>
