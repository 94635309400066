<script setup lang="ts">
import { Modal } from 'flowbite'
import type { ModalInterface, ModalOptions } from 'flowbite'
import type { Nutzer } from '~/notdienstportal'

const userStore = useUserStore()
const commonStore = useCommonStore()

const nutzer = reactive(userStore.savedUser ? useUtils.cloneDeep(userStore.savedUser) : {} as Nutzer)

const rules = {
  name: { required: requiredWithMessage('Bitte geben Sie den Namen an.') },
  mail: {
    required: requiredWithMessage('Bitte geben Sie die E-Mail-Adresse an.'),
    email: emailWithMessage('Bitte geben Sie eine gültige E-Mail-Adresse an.'),
  },
}
const v$ = useVuelidate(rules, nutzer as any, { $autoDirty: true })

const modalOptions: ModalOptions = {
  placement: 'center',
  closable: false,
  backdropClasses: 'bg-gray-900 bg-op-50 dark:bg-op-80 fixed inset-0 z-80',
  onHide: () => {
    const modalBackdrop = document.querySelector('[modal-backdrop]')
    if (modalBackdrop)
      modalBackdrop.remove()
  },
}

function show() {
  const tempNutzer = userStore.savedUser ? useUtils.cloneDeep(userStore.savedUser) : {} as Nutzer
  nutzer.nutzername = tempNutzer.nutzername
  nutzer.name = tempNutzer.name
  nutzer.mail = tempNutzer.mail
  nutzer.rolle = tempNutzer.rolle
  nutzer.region = tempNutzer.region

  const modal: ModalInterface = new Modal(document.getElementById('nutzerModal'), modalOptions)
  modal.show()
}

function hide() {
  const modal: ModalInterface = new Modal(document.getElementById('nutzerModal'), modalOptions)
  modal.hide()
}

async function save() {
  const isFormCorrect = await v$.value.$validate()

  if (!v$.value.$error && isFormCorrect) {
    commonStore.setLoading(true)
    api.updateNutzerInfo(nutzer).then((res) => {
      if (res.data.error) {
        useModal.handleError(res.data.errorText, undefined)
      }
      else {
        api.getNutzerInfo().then((nutzerRes) => {
          userStore.setUser(nutzerRes.data.nutzer)
          userStore.setBackendVersion(nutzerRes.data.version)
          // TODO remove, info is in nutzer enthalten
          userStore.setNotdienstaenderungenEingabeZusaetzlichesMerkmal(nutzerRes.data.nutzer.nutzerEinstellung.notdienstaenderungenEingabeZusaetzlichesMerkmal)
          hide()
        }).catch((err) => {
          // siehe App.vue
          // Token abgelaufen oder Nutzer nicht angemeldet oder Server nicht erreichbar
          // Zeige Fehler-Modal wenn ein Netzwerkfehler auftritt
          if (err.code === 'ERR_NETWORK')
            useModal.handleError('Es ist ein Fehler aufgetreten.<br> Der Server ist nicht erreichbar oder es gibt ein Problem mit Ihrer Internetverbindung.', err)

          userStore.setUser(undefined)
          userStore.setToken(undefined)
          localStorage.removeItem('token')
          hide()
        })
      }
    }).catch((error: any) => {
      useModal.handleError('Es ist ein Fehler beim Speichern aufgetreten.', error)
    }).finally(() => {
      commonStore.setLoading(false)
    })
  }
  else { useModal.handleInfo('Bitte kontrollieren Sie ihre Eingaben!') }
}

defineExpose({ show })
</script>

<template>
  <div id="nutzerModal" class="h-modal fixed left-0 right-0 top-4 z-100 hidden items-center justify-center overflow-x-hidden overflow-y-auto md:inset-0 sm:h-full" aria-hidden="true">
    <div class="relative h-full max-w-2xl w-full px-4 md:h-auto">
      <!-- Modal content -->
      <div class="relative border-1 rounded-lg bg-white shadow shadow dark:border-gray-600 dark:bg-gray-800">
        <!-- Modal header -->
        <div class="flex items-start justify-between border-b rounded-t p-5 dark:border-gray-600">
          <h3 class="text-xl font-semibold dark:text-white">
            Nutzer bearbeiten
          </h3>
          <button type="button" class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white" @click="hide">
            <div class="h-5 w-5" i-mdi-close />
            <span class="sr-only">Schließen</span>
          </button>
        </div>

        <!-- Modal body -->
        <div v-if="userStore.savedUser" class="p-6 space-y-6">
          <form class="grid md:gap-2">
            <!-- TheInput id="nutzername" v-model="nutzer.nutzername" type="text" label="Nutzername" placeholder="Mustermann" :v$="v$" / -->
            <TheOutput id="modal-nutzername" v-model="nutzer.nutzername" type="text" label="Nutzername" />
            <TheInput id="modal-nutzer-name" v-model="nutzer.name" type="text" label="Name" placeholder="Mustermann" :v$="v$" />
            <TheInput id="modal-mail" v-model="nutzer.mail" type="email" label="E-Mail-Adresse" placeholder="name(at)mail.de" :v$="v$" />
            <TheOutput id="modal-rolle" v-model="nutzer.rolle" type="text" label="Rolle" typename="EnumAuthRole" />
            <TheOutput id="modal-region" v-model="nutzer.region" type="text" label="Kammerbezirk" typename="EnumRegion" />
            <TheInput id="modal-password1" v-model="nutzer.passwort" type="password" label="Passwort" placeholder="**********" :v$="v$" />
          </form>
        </div>

        <!-- Modal footer -->
        <div class="flex items-center justify-between border-t border-gray-200 rounded-b p-6 dark:border-gray-600">
          <div />
          <button class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm text-white font-medium dark:bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="submit" @click="save">
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
