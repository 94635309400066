import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Nutzer, NutzerReduziert } from '~/notdienstportal'

const localStorageToken = localStorage.getItem('token') || undefined

export const useUserStore = defineStore('user', () => {
  /**
   * Current user
   */
  const savedUser = ref<Nutzer | undefined>(undefined)
  const reducedUserList = ref<NutzerReduziert[] | undefined>(undefined)
  const token = ref<string | undefined>(localStorageToken)
  const refUrl = ref<string | undefined>(undefined)
  const backendVersion = ref<string | undefined>(undefined)
  const notdienstaenderungenEingabeZusaetzlichesMerkmal = ref<boolean | undefined>(false)

  // auth fuer bereiche
  const canNutzer = ref<boolean>(false)
  const canApotheken = ref<boolean>(false)
  const canRst = ref<boolean>(false)
  const canPlaene = ref<boolean>(false)
  const canAntraege = ref<boolean>(false)
  const canEntfernungen = ref<boolean>(false)
  const canNNF = ref<boolean>(false)
  const canKarte = ref<boolean>(false)
  const canAdmin = ref<boolean>(false)
  const canSchnittstelleNutzer = ref<boolean>(false)
  const canNewsletterNutzer = ref<boolean>(false)

  const isAdmin = ref<boolean>(false)
  const isKammer = ref<boolean>(false)
  const isAdminOrKammer = ref<boolean>(false)

  /**
   * App related
   */

  function setUser(user: Nutzer | undefined) {
    savedUser.value = user

    // ['ROLE_ADMIN', 'ROLE_APOKAMMER_ADMIN', 'ROLE_APOKAMMER', 'ROLE_APOLEITER']
    canNutzer.value = !!user && [
      'ROLE_ADMIN',
      'ROLE_APOKAMMER_ADMIN',
    ].includes(user.rolle)
    canApotheken.value = !!user && [
      'ROLE_ADMIN',
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canRst.value = !!user && [
      'ROLE_ADMIN',
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canPlaene.value = !!user && [
      'ROLE_ADMIN',
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canAntraege.value = !!user && [
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canEntfernungen.value = !!user && [
      'ROLE_ADMIN',
    ].includes(user.rolle)
    canNNF.value = !!user && [
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canKarte.value = !!user && [
      'ROLE_ADMIN',
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)
    canAdmin.value = !!user && [
      'ROLE_ADMIN',
    ].includes(user.rolle)
    canSchnittstelleNutzer.value = !!user && [
      'ROLE_ADMIN',
    ].includes(user.rolle)
    canNewsletterNutzer.value = !!user && [
      'ROLE_ADMIN',
    ].includes(user.rolle)

    isAdmin.value = !!user && [
      'ROLE_ADMIN',
    ].includes(user.rolle)
    isKammer.value = !!user && [
      'ROLE_APOKAMMER_ADMIN',
      'ROLE_APOKAMMER',
    ].includes(user.rolle)

    isAdminOrKammer.value = !!user && (isAdmin.value || isKammer.value)
  }

  function setReducedUserList(newUserList: NutzerReduziert[] | undefined) {
    reducedUserList.value = newUserList
  }

  function setToken(newToken: string | undefined) {
    token.value = newToken
    if (newToken)
      localStorage.setItem('token', newToken)
  }

  function setRefUrl(url: string) {
    refUrl.value = url
  }

  function setBackendVersion(version: string | undefined) {
    backendVersion.value = version
  }

  function setNotdienstaenderungenEingabeZusaetzlichesMerkmal(value: boolean | undefined) {
    notdienstaenderungenEingabeZusaetzlichesMerkmal.value = value
  }

  return {
    savedUser,
    reducedUserList,
    token,
    refUrl,
    backendVersion,
    notdienstaenderungenEingabeZusaetzlichesMerkmal,
    canNutzer,
    canApotheken,
    canRst,
    canPlaene,
    canAntraege,
    canEntfernungen,
    canNNF,
    canKarte,
    canAdmin,
    canSchnittstelleNutzer,
    canNewsletterNutzer,
    isAdmin,
    isKammer,
    isAdminOrKammer,
    setUser,
    setReducedUserList,
    setToken,
    setRefUrl,
    setBackendVersion,
    setNotdienstaenderungenEingabeZusaetzlichesMerkmal,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore as any, import.meta.hot))
