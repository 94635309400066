<script setup lang="ts">
import NProgress from 'nprogress'
import LogoLight from '~/assets/apo-logo.png'

defineOptions({
  name: 'LoginComponent',
})

const userStore = useUserStore()

const loading = ref(false)
const nutzer = reactive({
  nutzername: '',
  passwort: '',
})

const rules = {
  nutzername: { required: requiredWithMessage('Bitte geben Sie einen Nutzernamen an.') },
  passwort: { required: requiredWithMessage('Bitte geben Sie das Passwort an.') },
}
const v$ = useVuelidate(rules, nutzer as any, { $autoDirty: true })

async function login() {
  const isFormCorrect = await v$.value.$validate()

  if (!v$.value.$error && isFormCorrect) {
    loading.value = true
    NProgress.start()

    api.login(nutzer.nutzername, nutzer.passwort).then((res) => {
      userStore.setToken(res.data.jwttoken)

      api.getNutzerInfo().then((res) => {
        userStore.setUser(res.data.nutzer)
        userStore.setBackendVersion(res.data.version)
        userStore.setNotdienstaenderungenEingabeZusaetzlichesMerkmal(res.data.nutzer.nutzerEinstellung.notdienstaenderungenEingabeZusaetzlichesMerkmal)
        nutzer.nutzername = ''
        nutzer.passwort = ''
      }).catch((error: any) => {
        useModal.handleError(undefined, error)
      })
    }).catch((error: any) => {
      // Fehlerhafte Anmeldung: Nutzername, Passwort
      if (error && (error as any).response && (error as any).response.data && (error as any).response.data.startsWith('INVALID_CREDENTIALS')) {
        useModal.handleError('<b>Ungültige Anmeldeinformationen oder . Bitte versuchen es erneut.</b>', error)
      }
      else {
        useModal.handleError(undefined, error)
      }
    }).finally(() => {
      loading.value = false
      NProgress.done()
    })
  }
}
</script>

<template>
  <main text="center dark:gray-200">
    <div class="login-bg pt:mt-0 mx-auto flex flex-col items-center justify-center px-6 pt-8 md:h-screen dark:bg-gray-900">
      <!-- Card -->
      <div class="max-w-xl w-full rounded-lg bg-white p-6 shadow space-y-8 dark:bg-gray-800 sm:p-8">
        <a href="/" class="mb-8 flex items-center justify-center text-2xl font-semibold lg:mb-10 dark:text-white">
          <img :src="LogoLight" class="mr-3 h-8 w-8" alt="sberg Notdienstportal Logo">
          <span>Notdienstportal</span>
        </a>
        <form>
          <TheInput
            id="nutzername"
            v-model="nutzer.nutzername"
            class="p-4 text-align-start"
            type="text"
            label="Nutzername"
            placeholder="Mustermann"
            bind-attrs
            :v$="v$"
            :disabled="loading"
            @keydown.enter.exact.stop.prevent=""
          />

          <TheInput
            id="passwort"
            v-model="nutzer.passwort"
            class="mb-0 p-4 text-align-start"
            type="password"
            label="Passwort"
            placeholder="Passwort"
            bind-attrs
            :v$="v$"
            :disabled="loading"
            @input="(event: any) => nutzer.passwort = event.target.value"
            @keydown.enter.exact.stop.prevent="login"
          />

          <div class="flex items-center justify-around">
            <TheButton
              id="login"
              label="Anmelden"
              class="text-center text-base"
              :disabled="loading || v$.$error || !v$.$anyDirty"
              :loading="loading"
              @click="login"
            />
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
